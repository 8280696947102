import React, { useState, useEffect, createContext } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { useMount, useUnmount } from 'react-use';
import { padding } from '../../styles/global'
import { media } from '../../styles/utils'

const SignUp = (props) => {
    const { signup_message } = props
	
	return (
		<Wrapper>
            <Container>
                <Description>
                    {props?.signup_message} <a href="http://eepurl.com/djB3Mn" target={'_blank'}>Sign up</a>.
                </Description>
            </Container>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: fixed;
    bottom: 0;
	left: 0;
	right: 0;
	height: 83px;
	background: #CFE3FA;
    z-index: 4;

    display: flex;
    justify-content: center;
    align-items: center;
`

const Container = styled.div`
    width: 1440px;
	max-width: 100%;
    ${padding}
	display: flex;
	justify-content: center;
    align-items: center;
    height: 100%;
`

const Description = styled.div`
  
    ${media.phone`
        max-width: 310px;
    `}

    &, * {
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: inherit;
        font-family: 'Helvetica', Arial, sans-serif;
        font-weight: 400;

    }

    a {
        text-decoration: underline;
    }
`

export default SignUp

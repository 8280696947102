import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { media } from '../../styles/utils'
import { container, bgIcon, padding, hoverState } from '../../styles/global'

import flag from '../../assets/images/Australian_Aboriginal_Flag.svg'
import scrollArrow from '../../assets/images/scroll-to-top.svg'
import bedroomSuck from '../../assets/images/bedroom-suck-logo.svg'

const Scroll = require('react-scroll')
var scroll = Scroll.animateScroll

const Footer = (props) => {
	const { first_nations } = props
	return (
		<Wrapper>
			<Grid>
				<Flag>
					<Icon src={flag} />
				</Flag>

				<Description
					dangerouslySetInnerHTML={{__html: first_nations}}
				/>

				<Info>
					<p>Follow us <a href="https://www.instagram.com/bedroomsuckrecords/" target="_blank">IG</a> <a href="https://www.facebook.com/bedroomsuckrecords/" target="_blank">FB</a></p>
					<p>
						&#169; Bedroom Suck 2020<br />
						Made with More Studio
					</p>
				</Info>

				<Nav>
					<ScrollToTop
						onClick={scroll.scrollToTop}
					>
						<Label>Top</Label><Icon src={scrollArrow} />
					</ScrollToTop>
				</Nav>
				
				<Logo
					href="https://bedroomsuckrecords.com/"
					target="_blank"
				>
					<Icon src={bedroomSuck} />
				</Logo>
			</Grid>
		</Wrapper>
	)
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const Icon = styled.img``
const Label = styled.div``
const Nav = styled.div``

const Grid = styled.div`
	display: flex;
`


const footerText = css`
	&, * {
		font-family: 'Courier';
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 97.7%;
	}
`

const Flag = styled.div``
const ScrollToTop = styled.div``
const Logo = styled.a``

const Wrapper = styled.div`
	max-width: 100%;
	${padding};
	padding-top: 132px;
	padding-bottom: 102px;

	${media.phone`
		padding-top: 62px;
	`}

	${Grid} {
		flex-wrap: wrap;
	}

	${Flag} {
		margin-top: 4px;
		margin-right: 14px;

		${media.phone`
			display: none;
		`}

		${Icon} {
			width: 98px;
			height: 66px;
		}
	}

	${Description} {
		flex: 1;
		max-width: 700px;
		margin-right: 14px;
		${footerText}

		${media.phone`
			flex: none;
			width: 100%;
			margin-bottom: 40px;

			&, * {
				font-size: 12px;
				line-height: 97.7%;
				text-align: justify;
			}
		`}
	}

	${Info} {
		margin-right: 68px;
		text-align: center;
		${footerText};

		${media.phone`
			margin-right: auto;
		`}
	}

	${Nav} {
		margin-right: 120px;
		margin-left: auto;

		${media.phone`
			margin-left: 50px;
			margin-right: 0;
		`}

		${ScrollToTop} {
			display: flex;
			flex-direction: row;
			align-items: center;
			cursor: pointer;

			${Label} {
				text-transform: uppercase;
				${footerText};
			}

			${Icon} {
				height: 10px;
				width: 11px;
				margin-top: 3px;
				margin-left: 3px;
			}
		}
	}

	${Logo} {
		${media.phone`
			margin-top: 20px;
			margin-left: auto;
			margin-right: auto;
		`}
	}
`

export default Footer

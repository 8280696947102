import React, { useState, useEffect } from 'react'
import styled, { css, createGlobalStyle } from 'styled-components'
import { useMount, useMedia } from 'react-use'
import Marquee from 'react-marquee-slider'
import times from "lodash/times"

import { isClient } from '../../utils'
import { media } from '../../styles/utils'
import { container, padding, hoverState } from '../../styles/global'

const Ticker = props => {
    const { content } = props

    return (
        <Wrapper className='text-ticker'>
            <Marquee
                key={'text-ticker'}
                velocity={15}
            >
                {times(2, Number).map(id => (
                    <Description key={id}
                        dangerouslySetInnerHTML={{__html: content}}
                    />
                ))}
            </Marquee>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 304px;
`

const Description = styled.div`
    display: inline-block;
    margin-right: 304px; /* full width of ticker */
    font-size: 22.1818px;
    line-height: 25px;
`

export default Ticker
